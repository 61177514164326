.social__links ul li {
  margin: 1rem 2rem;
  padding: 0.6rem 1.4rem;
  background: #333;
  backdrop-filter: blur(0px);
  border-radius: 5px;
  transition: background 0.25s;
  transform-style: preserve-3d;
  perspective: 500px;
  color: #ffff;
}

.social__links ul li:first-child {
  margin-top: 1.4rem;
}

.heading__text {
  font-size: 1rem;
}

.sub-heading__text {
  font-size: 0.8rem;
}

.social__links ul li a {
  width: 100%;
  display: flex;
  align-items: center;
  color: #ffff;
  font-size: 1rem;
}
.social-detail__block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social__icons svg {
  font-size: 1.4rem;
  width: 1.4rem;
  margin-right: 1.4rem;
}

.social__links ul li a i {
  text-align: center;
  line-height: 4rem;
  font-size: 1.8rem;
  text-shadow: 2px 2px 12px rgba(0, 0, 0, 0.5);
  transform: scale(1.4) translateZ(50px);
  transition: 0.25s;
}
.social__links ul li a span {
  line-height: 1.4rem;
}

.social__links ul .social__container:hover {
  background: #333;
}
@media only screen and (max-width: 549px) {
  .container {
    width: 88vw;
  }
}
