.head__section img {
  display: block;
  margin: 2rem auto;
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 50%;
}

.head__section p {
  text-align: center;
  color: #fff;
  margin: 2rem 2rem 1.4rem 2rem;
}

.head__section p span.bold-text {
  font-weight: 600;
}

.media {
  /* border: 1px solid white; */
  display: grid;
  place-items: center;
}

.media-details {
  display: flex;
  justify-content: space-between;
  margin: 0rem 3rem;
  color: white;
  flex: 1;
}

.media-tap {
  background: #333;
  padding: 0.8rem;
  border-radius: 100%;
  display: grid;
  place-items: center;
}

.media-tap svg {
  color: #fff;
  font-size: 1.2rem;
  width: 1.2rem;
}

.icon-sub-text {
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
