.container {
  width: 100%;
  margin-top: 18%;
  /* background-color: blue; */
}

@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .container {
    margin-top: 10%;
    /* background-color: red; */
  }

  .home__container {
    margin-top: 90%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    margin-top: 10%;
    /* background-color: yellow; */
  }
  .home__container {
    margin-top: 90%;
  }

  .bg-img {
    width: 16rem;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    margin-top: 12%;
    /* background-color: pink; */
  }
  .home__container {
    margin-top: 130%;
  }
  .bg-img {
    width: 12rem;
  }
}

@media only screen and (max-width: 480px) {
  .container {
    margin-top: 14%;
    /* background-color: orange; */
  }

  .home__container {
    margin-top: 150%;
  }

  .bg-img {
    width: 10rem;
  }

  .social__links ul li {
    margin: 1rem 1.4rem;
    padding: 0.4rem 1.2rem;
  }

  .social__icons svg {
    margin-right: 1.2rem;
  }

  .media-details {
    margin: 0rem 2.525rem;
    gap: 0.5rem;
  }
  .icon-sub-text {
    margin-top: 0.5rem;
    font-size: 0.725rem;
  }
}

@media only screen and (max-width: 380px) {
  .container {
    margin-top: 20%;
    /* background-color: green; */
  }

  .home__container {
    margin-top: 180%;
  }

  .bg-img {
    width: 8rem;
  }

  .heading__text {
    font-size: 0.9rem;
  }

  .sub-heading__text {
    font-size: 0.6rem;
  }

  .social__icons svg {
    font-size: 1.2rem;
    width: 1.2rem;
  }

  .media-details {
    margin: 0rem 1.8rem;
    gap: 0.5rem;
  }

  .icon-sub-text {
    margin-top: 0.5rem;
    font-size: 0.6rem;
  }
}
